import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import TDesign from 'tdesign-vue';
import 'element-ui/lib/theme-chalk/index.css';
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';
import './assets/css/mystyle.css'
import axios from 'axios'
//导入代码高亮文件
import hljs from 'highlight.js'
//导入代码高亮样式
import 'highlight.js/styles/a11y-light.css'


axios.defaults.timeout = 300000;
Vue.use(ElementUI);
Vue.use(TDesign);
Vue.config.productionTip = false
//定义一个代码高亮指令
Vue.directive('highlight', function (el) {
  let highlight = el.querySelectorAll('pre code');
  highlight.forEach((block) => {
      hljs.highlightBlock(block)
  })
})
// 初始化
Vue.prototype.$hljs = hljs;
// 本地接口
// Vue.prototype.$host = "http://localhost:80/peixun/"
// Vue.prototype.$img = "http://localhost:80/peixun/img/"
// Vue.prototype.$logo = "http://localhost:80/peixun/img/logo1.png"
// Vue.prototype.$mainlogo = "http://localhost:80/peixun/img/mainlogo.png"
// Vue.prototype.$wjh = "http://localhost:80/szjsb/"
// Vue.prototype.$yz = "http://localhost:80/yz/"
// Vue.prototype.$yzimg = "http://localhost:80/yz/img/"
// Vue.prototype.$zclocal = "http://localhost:80/tool/"
// 生产接口
Vue.prototype.$yz = "https://wiki.kefan.com/yz/"
Vue.prototype.$wjh = "https://wiki.kefan.com/szjsb/"
Vue.prototype.$host = "https://wiki.kefan.com/peixun/"
Vue.prototype.$img = "https://wiki.kefan.com/peixun/img/"
Vue.prototype.$logo = "https://wiki.kefan.com/peixun/img/logo1.png"
Vue.prototype.$mainlogo = "https://wiki.kefan.com/peixun/img/mainlogo.png"
Vue.prototype.$zclocal = "https://wiki.kefan.com/tool/"
Vue.prototype.$cbximg = "https://wiki.kefan.com/cbx/img/"

// 创建一个全局函数，封装axios的post请求
Vue.prototype.$post = function(url, data) {
  return axios({
    method: 'post',
    url: url,
    data: data
  });
};

// 创建一个全局函数，封装axios的get请求
Vue.prototype.$get = function(url, params) {
  return axios({
    method: 'get',
    url: url,
    params: params
  });
};

Vue.prototype.$api = {
   "login":"login.php",//登录接口
   "userinfo":"userinfo.php",//获取用户信息接口
   "xcximg":"xcximg.php",//获取全园区小程序码图片接口
   "allyuanqubumeng":"allyuanqubumeng.php",//获取全园区部门列表
   "anlifenlei":"anlifenlei.php",//获取案例分类列表
   "alteruserinfo":"alteruserinfo.php",//更改用户信息
   "up":"upload.php",//上传头像地址
   "submitanli":"submitanli.php",//提交案例
   "anli":"anli.php",//案例获取
   "deleteanli":"deleteanli.php",//删除案例
   "anlinums":"anlinums.php",//各案例分类下案例的个数
   "article":"article.php",//获取案例详情
   "good":"good.php",//点赞
   "pinglun":"pinglun.php",//评论
   "view":"view.php",//浏览
   "goodanli":"goodanli.php",//获取推荐案例
   "simple":"simple.php",//通用接口
   "zcnormal":"zcnormal.php",//资产通用接口
   "cbxbill":"cbxbill.php",
   "cbxexcel":"cbxexcel.php",
   "life":"shenghuo/life.php",//生活+接口
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
